<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="departmentModel"
    label-width="100px"
    >
    <el-form-item label="名称：" prop="departmentName">
      <el-input
        type="text"
        v-model="departmentModel.departmentName">
      </el-input>
    </el-form-item>
    <el-form-item label="代码：" prop="departmentCode">
      <el-input
        type="text"
        v-model="departmentModel.departmentCode">
      </el-input>
    </el-form-item>
    <template v-if="!departmentModel.resourceId">
      <el-form-item label="所属公司：">
        <el-select v-model="companyGuid" clearable>
          <el-option
            v-for="company in companys"
            :key="company.companyGuid"
            :label="company.companyName"
            :value="company.companyGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属项目：" prop="projectGuid">
        <el-select v-model="departmentModel.projectGuid" clearable>
          <el-option
            v-for="project in projects"
            :key="project.projectGuid"
            :label="project.projectName"
            :value="project.projectGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
    </template>
    <template v-else>
      <el-form-item label="所属项目：">
        <el-input
          disabled
          :value="departmentModel.projectName">
        </el-input>
      </el-form-item>
    </template>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="departmentModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import DepartmentModel from '@/model/DepartmentModel'
import GetProject from '@/mixins/GetProject'

export default {
  name: 'DepartmentForm',
  mixins: [
    GetProject
  ],
  props: {
    departmentModel: {
      type: DepartmentModel,
      default () {
        return new DepartmentModel()
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.departmentModel.projectGuid = ''
      this.projects = []
      newVal && this._getProjects(newVal)
    }
  },
  data () {
    return {
      companyGuid: '',
      rules: {
        departmentName: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        departmentCode: [
          {
            required: true,
            message: '请输入代码',
            trigger: 'blur'
          }
        ],
        projectGuid: [
          {
            required: true,
            message: '请选择所属项目',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    }
  },
  created () {
  }
}
</script>
