import BaseService from '@/services/BaseService'

let resourceName = 'project'

class ProjectService extends BaseService {
}

const projectService = new ProjectService(resourceName)

export default projectService
