import BaseService from '@/services/BaseService'

let resourceName = 'company'

class CompanyService extends BaseService {
}

const companyService = new CompanyService(resourceName)

export default companyService
