import utility from '@/common/utility'
import auth from '@/common/auth'

export default class DepartmentModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.departmentGuid
      this.departmentGuid = props.departmentGuid
      this.departmentName = props.departmentName
      this.departmentCode = props.departmentCode
      this.projectGuid = props.projectGuid
      this.projectName = props.projectName
      this.staffName = props.staffName
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.departmentGuid) this.departmentGuid = ''
    if (!this.departmentName) this.departmentName = ''
    if (!this.departmentCode) this.departmentCode = ''
    if (!this.projectGuid) this.projectGuid = ''
    if (!this.projectName) this.projectName = ''
    if (!this.staffName) this.staffName = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.departmentGuid = utility.getUuid()
    this.resourceId = this.departmentGuid
  }
}
