import BaseService from '@/services/BaseService'

let resourceName = 'department'

class DepartmentService extends BaseService {
}

const departmentService = new DepartmentService(resourceName)

export default departmentService
